import { CardCountdown, CardName, CardPlace, FlyButton, Gallery, Gap, VIP } from 'components'
import React, { useEffect } from 'react'
import './content.scss'
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import Quote from 'components/molecules/Quote';
import { Fade } from 'react-awesome-reveal';
import { SwipeUp } from 'assets';
import { Link } from 'react-scroll';

function Content(props) {
    const { t } = useTranslation();
    const {venue, guestVIP, handleMusic} = props;

    useEffect(() =>{
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                handleMusic('PLAYING')
            } else {
                handleMusic('PAUSED')
            }
        })
    },[handleMusic])

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            return 'tes';
        } else {
            return <CardCountdown days={days} hours={hours} minutes={minutes} seconds={seconds}/>
        }
    };

    return (
        <div className='content'>
            
            <div className='opening' id='home'>
                <div className='container'>
                    <div>
                        <div className='opening-title'>{t('opening_title')}</div>
                        <Gap height={20}/>
                        <div className='title-name'>Putri & Alvin</div>
                        <div className='date'>25 . 01 . 2025</div>
                        <Gap height={6}/>
                        <div className='opening-tag'>#ALoveYU</div>
                        {/* <div className='swipe-more'>{t('swipe_up')} <img alt='' width={14} height={14} src={SwipeUp}/></div> */}
                        <Link className='section' to='greeting' spy={true} smooth={true} duration={200}>
                            <div className='swipe-more'>{t('swipe_up')} <img alt='' width={14} height={14} src={SwipeUp}/></div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className=''>
                <Quote 
                    type='greeting'
                    title={t('quote_greeting_title')}
                    text={t('quote_greting_desc')}
                    id='greeting'
                />
                <Quote 
                    type='quote1'
                    title={t('quote_bible_1_title')}
                    text={'"'+ t('quote_bible_1') + '"'}
                />
                <CardName venue={venue} />
                <div className='menghitung-hari' id='menghitung-hari'>
                    <Fade duration={1000} delay={200} cascade>
                        <Gap height={20}/>
                        <div>
                            <div className='title'>{t('counting_day')}</div>
                            <div className='date'>{t('day_date')}</div>
                            <Countdown
                                date={(venue === 'reception' || venue === '') ?'2025-01-25T13:00:00' :'2025-01-25T09:00:00'}
                                renderer={renderer}
                            />
                        </div>
                        <div style={{display: (venue === 'matrimony' || venue === 'all' || venue === 'sakramen') ? 'block' : 'none'}}>
                            <CardPlace 
                                title={t('holy_matrimony')}
                                clock='09:00 - 11:00'
                                place='Gereja Kristus Raja Baciro'
                                urlGmaps='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3952.9787772831783!2d110.38683067412065!3d-7.7920713773308385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a59d773957dad%3A0xae048b4c2addab14!2sGereja%20Katolik%20Kristus%20Raja%2C%20Baciro!5e0!3m2!1sid!2sid!4v1710404851076!5m2!1sid!2sid'
                                urlDirection='https://www.google.com/maps/place/Gereja+Katolik+Kristus+Raja,+Baciro/@-7.7920714,110.3868307,17z/data=!3m1!4b1!4m6!3m5!1s0x2e7a59d773957dad:0xae048b4c2addab14!8m2!3d-7.7920767!4d110.3894056!16s%2Fg%2F1hm2rs7dc?entry=ttu'
                                urlCalendar='https://calendar.google.com/calendar/render?action=TEMPLATE&text=The+Wedding+of+Putri+%26+Alvin&location=Gereja+Katolik+Kristus+Raja,+Baciro,+Jl.+Melati+Wetan+No.47,+Baciro,+Kec.+Gondokusuman,+Kota+Yogyakarta,+Daerah+Istimewa+Yogyakarta+55225&dates=20250125T090000/20250125T110000&details=Wedding+of+Asteria+Agusti+Yuwita+Putri+and+Albertus+Alvin+Adhitama+Susanto'
                            />
                        </div>
                        <div style={{display: (venue === 'reception' || venue === 'all' || venue === 'sakramen') ? 'block' : 'none'}}>
                            <CardPlace 
                                title={t('reception')}
                                clock='13:00 - 15:00'
                                place='Gedung Auditorium LPP Yogyakarta'
                                urlGmaps='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.057318318224!2d110.38268837412045!3d-7.7837478772332975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a59ce0c90f2d7%3A0x4b47d753a8bfb425!2sGedung%20Auditorium%20LPP%20Yogyakarta!5e0!3m2!1sid!2sid!4v1710405046370!5m2!1sid!2sid'
                                urlDirection='https://www.google.com/maps/place/Gedung+Auditorium+LPP+Yogyakarta/@-7.7837479,110.3826884,17z/data=!3m1!4b1!4m6!3m5!1s0x2e7a59ce0c90f2d7:0x4b47d753a8bfb425!8m2!3d-7.7837532!4d110.3852633!16s%2Fg%2F11csb3335x?entry=ttu'
                                urlCalendar='https://calendar.google.com/calendar/render?action=TEMPLATE&text=The+Wedding+of+Putri+%26+Alvin&location=Gedung+Auditorium+LPP+Yogyakarta&dates=20250125T130000/20250125T150000&details=Wedding+of+Asteria+Agusti+Yuwita+Putri+and+Albertus+Alvin+Adhitama+Susanto'
                            />
                        </div>
                        <Gap height={20}/>
                    </Fade>
                </div>
                {(venue === 'all' || venue === 'reception' || venue === 'matrimony') &&
                    <Gallery />
                }
                {guestVIP && <VIP />}
                <Quote 
                    type='quote2'
                    title={t('quote_bible_2_title')}
                    text={'"'+ t('quote_bible_2') + '"'}
                />
                <Quote 
                    type='closing'
                    id='thank-you'
                    text={t('closing')}
                    venue={venue}
                />
            </div>
            <FlyButton venue={venue} />
        </div>
    )
}

export default Content