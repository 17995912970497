import React from 'react'
import './quote.scss'
import { Fade } from 'react-awesome-reveal'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next'

function Quote(props) {
    const { t } = useTranslation();

    const closingSpecial = () => (
        <>
            <div className='name'>
                Putri & Alvin
            </div>
            <Gap height={30}/>
            <div className='hashtag'>
                #ALoveYU
            </div>
        </>
    )

    const closingDefault = () => (
        <>
            <div className='name'>
                Putri & Alvin
            </div>
            <div className='parent'>
                {t('closing_family_1')}
            </div>
            <Gap height={8}/>
            <div className='parent'>
                {t('closing_family_2')}
            </div>
            <Gap height={30}/>
            <div className='hashtag'>
                #ALoveYU
            </div>
        </>
    )

    return (
        <div className={'quote '+ props.type} id={props.id}>
            <Fade duration={1200} delay={300} cascade>
                <div className='title'>{props.title}</div>
                <Gap height={10}/>
                <div>{props.text}</div>
                {props.type === 'closing' &&
                    (props.venue === 'all' || props.venue === 'reception' || props.venue === 'matrimony' ?
                        closingDefault()
                    :
                    closingSpecial()
                )}
            </Fade>
        </div>
    )
}

export default Quote