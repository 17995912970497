import React from 'react'
import './card-name.scss'
import { Image } from 'react-bootstrap'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-awesome-reveal';

function CardName(props) {
    const { t } = useTranslation();
    const { venue } = props;

    return (
        <div className='card-name' id='card-name'>
            <Gap height={10}/>
            <Fade duration={1200} delay={300} cascade>
                <div style={{color: 'black'}}>
                    <Image src='https://aloveyu.com/images/putri-close-up.webp' height={190} width={190} style={{objectFit:'cover'}}/>
                    <Gap height={12}/>
                    <div className='name'>Asteria Agusti Yuwita Putri</div>
                    <Gap height={4}/>
                    
                    {(venue === 'all' || venue === 'reception' || venue === 'matrimony') ?
                        <>
                            <div>{t('daughter_of')}{t('father_1')}</div>
                            <div>{t('mother_1')}(&#128327;)</div>
                        </>
                    :
                        <div> Putri Bapak Dwi dan Ibu Yuli</div>
                    }
                </div>
            </Fade>
            <Gap height={125}/>
            <Fade duration={1200} delay={300} cascade>
                <div>
                    <Image src='https://aloveyu.com/images/alvin-close-up.webp' height={190} width={190} style={{objectFit:'cover'}}/>
                    <Gap height={12}/>
                    {(venue === 'all' || venue === 'reception' || venue === 'matrimony') ?
                        <div className='name'>Albertus Alvin Adhitama Susanto</div>
                    :
                        <div className='name'>Albertus Alvin Adhitama</div>
                    }
                    <Gap height={4}/>

                    {(venue === 'all' || venue === 'reception' || venue === 'matrimony') ?
                        <>
                            <div>{t('son_of')}{t('father_2')}</div>
                            <div>{t('mother_2')}</div>
                        </>
                    :
                        <div> Putra Bapak Benny dan Ibu Ratna</div>
                    }
                </div>
            </Fade>
            <Gap height={10}/>
        </div>
    )
}

export default CardName