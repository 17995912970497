import React from 'react'
import './cardPlace.scss'
import { Button } from 'react-bootstrap'
import { Gap } from 'components/atoms'
import { useTranslation } from 'react-i18next'

function CardPlace(props) {
    const {title, clock, place, urlDirection, urlCalendar} = props
    const { t } = useTranslation();

    return (
        <div className='card-place'>
            <div className='title'>{title}</div>
            <div>{t('day_date')}</div>
            <div>{clock} WIB</div>
            <div>{place}</div>
            <Gap height={20} />
            <Button variant='aloveyu-outline' target="_blank" rel="noopener noreferrer"
                href={urlDirection}
            >
                {t('label_map')}
            </Button>
            <Gap height={10} />
            <Button 
                variant='aloveyu'
                target="_blank" rel="noopener noreferrer"
                href={urlCalendar}
            >
                {t('add_calendar')}
            </Button>
        </div>
    )
}

export default CardPlace